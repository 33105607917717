<jsdaddy-grid class="grid" hideMobile />
@if (content()) {
    <jsdaddy-section-wrapper>
        <jsdaddy-breadcrumbs isBlack />

        <div class="row container">
            <div class="column content">
                @if (content()) {
                    <jsdaddy-career-details-header
                        [career]="content()" /><jsdaddy-career-details-content
                        [career]="content()" /><jsdaddy-career-details-tell-us
                        [title]="content().title" />
                }
            </div>
            <jsdaddy-career-details-sidebar />
        </div>
    </jsdaddy-section-wrapper>
}
