import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
// type-coverage:ignore-next-line
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormItem } from '@shared/form-item/form.item.type';
import { FormSendingDialogService } from '@shared/form-sending-dialog/form-sending-dialog.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { BehaviorSubject, EMPTY, catchError, combineLatest, map, tap } from 'rxjs';

@Injectable()
export class CareerDetailsTellUsService {
    private readonly http = inject(HttpClient);

    public readonly nameValidators = [Validators.required];
    public readonly emailValidators = [Validators.required, Validators.email];

    private readonly file$$ = new BehaviorSubject<File | null>(null);
    private readonly fileError$$ = new BehaviorSubject<string>('');

    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly fb = inject(FormBuilder);
    private readonly formSendingDialogService = inject(FormSendingDialogService);

    public fileAndError$ = combineLatest([this.file$$, this.fileError$$]).pipe(
        map(([file, fileError]) => ({ file, fileError }))
    );

    public form = this.fb.group({
        name: [null, this.nameValidators],
        lastName: [null, this.nameValidators],
        email: [null, this.emailValidators],
    });

    public readonly steps: FormItem[] = [
        {
            formControlName: 'name',
            validators: this.nameValidators,
            placeholder: 'First name',
        },
        {
            formControlName: 'lastName',
            validators: this.nameValidators,
            placeholder: 'Last Name',
        },
        {
            formControlName: 'email',
            validators: this.emailValidators,
            placeholder: 'Email',
        },
    ];

    public dropped(files: NgxFileDropEntry[]): void {
        const firstFile = files?.[0];
        if (firstFile) {
            const extensions = ['pdf', 'docx', 'doc'];
            //type-coverage:ignore-next-line
            const fileEntry = firstFile.fileEntry as FileSystemFileEntry;

            fileEntry.file((file: File) => {
                const extension: string = file.name.split('.').at(-1) ?? '';
                const fileSizeInMb = file.size / (1024 * 1024);
                if (!extensions.includes(extension)) {
                    this.setFileError('Only pdf, docx, doc');
                    return;
                }
                if (fileSizeInMb > 10) {
                    this.setFileError('File is large');
                    return;
                }
                this.file$$.next(file);
                this.fileError$$.next('');
            });
        }
    }

    public clearFile(): void {
        this.file$$.next(null);
        this.fileError$$.next('');
    }

    private setFileError(error: string): void {
        if (error) {
            this.file$$.next(null);
            this.fileError$$.next(error);
        }
    }

    public sendEmail(title: string): void {
        const file = this.file$$.getValue();
        if (this.form.invalid || !file) {
            this.form.markAllAsTouched();
            return;
        }
        const { name, email, lastName } = this.form.value;
        this.formSendingDialogService.open();
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const formData = new FormData();
        const cv = this.file$$.getValue();
        if (email && name && lastName && cv) {
            formData.append('email', email);
            formData.append('name', name);
            formData.append('lastName', lastName);
            formData.append('file', cv, cv.name);
            formData.append('title', title);
            this.http
                .post<string>('/send-cv', formData)
                .pipe(
                    tap(() => {
                        this.resetForm();
                        return this.formSendingDialogService.changeToSuccess();
                    }),
                    catchError(() => {
                        this.resetForm();
                        this.formSendingDialogService.changeToError();
                        return EMPTY;
                    })
                )
                .subscribe();
        }
    }

    public resetForm(): void {
        this.form.reset();
        this.file$$.next(null);
    }
}
